<template>
  <v-list-item-group>
    <template v-if="!isAuthenticated">
      <LanguageMenuItem />
      <v-list-item active-class="primary" :to="toLogin">
        <v-list-item-icon>
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("loginOrRegisterItem") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-else>
      <template v-for="({ title, icon, to, showItem }, i) in links">
        <v-list-item active-class="primary" :to="to" v-if="showItem" :key="i">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <LanguageMenuItem />
      <LogoutItem />
    </template>
  </v-list-item-group>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import {
  authRoutesName,
  userRoutesName,
  prizesRoutesName,
} from "@/router/names";
import { isAuthenticated, getIsKioskSetting } from "@/helpers";
import { settingsDigitalLetterStoreGetters } from "@/store/modules/settingsDigitalLetter/constNames";

export default {
  name: "ListItemGroupUser",
  components: {
    LanguageMenuItem: () => import("./LanguageMenuItem.vue"),
    LogoutItem: () => import("./LogoutItem.vue"),
  },
  computed: {
    links() {
      return [
        {
          title: i18n.t("orders"),
          icon: "mdi-order-bool-ascending-variant",
          to: { name: "OrdersIndex" },
          showItem: !getIsKioskSetting,
        },
        {
          title: i18n.t("prizes"),
          icon: "mdi-trophy",
          to: { name: prizesRoutesName.INDEX.name },
          showItem:
            !getIsKioskSetting &&
            this.$store.getters[
              settingsDigitalLetterStoreGetters.GET_USE_AWARDS
            ],
        },
        {
          title: i18n.t("myAccountItem"),
          icon: "mdi-account",
          to: { name: userRoutesName.INDEX.name },
          showItem: !getIsKioskSetting,
        },
      ];
    },
    isKiosk() {
      return getIsKioskSetting;
    },
    isAuthenticated() {
      return isAuthenticated();
    },
    toLogin: () => ({ name: authRoutesName.INDEX.name }),
  },
};
</script>
